<template>
  <v-autocomplete
    v-model="model"
    :items="$t('constants.rawMaterials.list')"
    :loading="isLoading"
    cache-items
    item-text="text"
    item-value="value"
    :label="isMultiple ? $tc(label, 2) : $tc(label, 1)"
    :multiple="isMultiple"
    :disabled="hasDisabledInput"
    :clearable="isClearable"
    outlined
    hide-details
    :search-input.sync="search"
    class="text-capitalize"
    :return-object="returnObject"
    :rules="isRequired ? requiredRules : []"
    :validate-on-blur="isRequired"
    @input="onInput">

    <template #selection="{ item }">
      <span class="firstLetterUpper" style="text-transform: lowercase !important;">{{ item.text }}</span>
    </template>

    <template #item="{ item }">
      <v-list-item-content>
        <v-list-item-title class="firstLetterUpper" style="text-transform: lowercase !important;">
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

  </v-autocomplete>
</template>

<script>
import formRules from "@/mixins/formRules";

export default {
  name: "SelectRawMaterial",

  mixins: [formRules],

  props: {
    isRequired: {type: Boolean, default: false},
    isClearable: {type: Boolean, default: true},
    isMultiple: {type: Boolean, default: false},
    hasDisabledInput: {type: Boolean, default: false},
    // eslint-disable-next-line vue/require-prop-type-constructor
    value: {type: String|Object, default: () => null},
    label: {type: String, default: "common.rawMaterials.material"},
    returnObject: {type: Boolean, default: true},
  },

  components: {
  },

  data() {
    return {
      items: [],
      isLoading: false,
      model: null,
      search: null,
      timerId: null,
      timerCount: 500
    }
  },

  created() {
    this.model = this.value;
  },

  methods: {
    onInput(val) {
      this.$emit("input", val);
      this.search = "";
    }
  },
  watch: {
    search(val) {
    }
  }
}
</script>

<style scoped>

</style>
